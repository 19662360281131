import Layout from "../../components/Layout"
import { Link } from "gatsby"
import React from "react"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const BySeriesPage = () => {
 

  return (
    <Layout >
      <Seo title="Talman guitars by series" />
      
      <div className="max-w-1920 mx-auto p-5 lg:p-12">
        <h1 className="page-heading mb-10">Guitars by series</h1>


        <section className="landing-banner">
          <Link to="/guitars/vintage" className="banner-link relative z-10">
            <h2 className="text-2xl lg:text-6xl lg:pl-10 font-bold">Vintage</h2>
            <p className="lg:pl-10">The original run of Talman electric guitars. 1994-1998</p>
          </Link>

          <div className="absolute z-0 left-0 top-1/2 -translate-y-1/2  w-full group-hover:scale-110 transform transition duration-300"><StaticImage layout="fullWidth"  src="../../assets/images/by-series/vintage-banner.jpg" alt="TC420 sparkle finish Talman in a hardcase" quality={75}  /></div>
        </section>

        <section className="landing-banner">
          <Link to="/guitars/artcore" className="banner-link relative z-10">
          <h2 className="text-2xl lg:text-6xl lg:pl-10 font-bold">Artcore</h2>
          <p className="lg:pl-10">The semi-hollow Talman guitars. 2002-2005</p>
          </Link>
        </section>

        <section className="landing-banner">
          <Link to="/guitars/reissue" className="banner-link relative z-10">
          <h2 className="text-2xl lg:text-6xl lg:pl-10 font-bold">Reissue</h2>
          <p className="lg:pl-10">The modern era Talman guitars. 2015+</p>
          </Link>
        </section>

        <section className="landing-banner">
          <Link to="/guitars/signature" className="banner-link relative z-10">
          <h2 className="text-2xl lg:text-6xl lg:pl-10 font-bold">Signature models</h2>
          <p className="lg:pl-10">The Ibanez artist signature model Talman guitars.</p>
          </Link>
        </section>
      </div>
    </Layout>
  )
}

export default BySeriesPage